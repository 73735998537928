import { FC } from 'react'
import LogoSvg from '@/public/images/common/toshika-logo.svg'
import { useRouter } from 'next/router'
import { isJaAndAtTopPage } from '@/utils'
import classNames from 'classnames'
import styles from './index.module.scss'

export const Logo: FC = () => {
  const router = useRouter()
  return (
    <span
      className={classNames(styles.container, styles.containerWithoutDropDown, {
        [styles.whiteContainer]: isJaAndAtTopPage(router),
      })}
    >
      <div className={styles.desktop}>
        <LogoSvg />
      </div>
      <div className={styles.mobile}>
        <LogoSvg />
      </div>
    </span>
  )
}
