import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { getUserAvatar, useStores } from '@/utils'
import { Avatar2 } from '@components/atoms/Avatar2'
import { OutSideOverlay } from '@components/atoms/OutSideOverlay'
import { GlobalDesktopHeaderAccountMenuDropdown } from '@components/organisms/GlobalDesktopHeaderAccountMenuDropdown'
import styles from './index.module.scss'

export const GlobalDesktopHeaderAccountMenu: FC = observer(() => {
  const { viewer } = useStores()
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropDown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.button} onClick={toggleDropDown} onKeyPress={toggleDropDown} role='button' tabIndex={0}>
          <Avatar2 size='md' src={getUserAvatar(viewer.viewer)} isFocused={isOpen} />
        </div>
        <div className={styles.dropDown}>
          {isOpen && <GlobalDesktopHeaderAccountMenuDropdown toggleDropDown={toggleDropDown} />}
        </div>
      </div>
      <OutSideOverlay onClick={toggleDropDown} isOpen={isOpen} />
    </>
  )
})
