import { FC, PropsWithChildren } from 'react'
import SuccessIconSvg from '@/public/images/common/check_circle_FILL0_icon_v2.svg'
import ErrorIconSvg from '@/public/images/common/error_FILL0_icon_v2.svg'
import WarningIconSvg from '@/public/images/common/warning_FILL0_icon_v2.svg'
import CloseIconSvg from '@/public/images/common/close_FILL0_icon_v2.svg'
import classNames from 'classnames'
import { IconButton } from '@components/atoms/IconButton'
import { Button } from '@components/atoms/Button'
import styles from './index.module.scss'

type Props = {
  type?: 'default' | 'success' | 'warning' | 'danger'
  withCloseButton?: boolean
  withActionButton?: boolean
  actionButtonText?: string
  onClickButton?: () => void
}

export const Note: FC<PropsWithChildren<Props>> = ({
  type = 'default',
  withCloseButton = false,
  withActionButton = false,
  actionButtonText = '',
  onClickButton = () => {},
  children,
}) => {
  const getIcon = () => {
    switch (type) {
      case 'success':
        return <SuccessIconSvg viewBox='0 0 20 20' />
      case 'warning':
        return <WarningIconSvg viewBox='0 0 20 20' />

      case 'danger':
        return <ErrorIconSvg viewBox='0 0 20 20' />
      default:
        return null
    }
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.default]: type === 'default',
        [styles.success]: type === 'success',
        [styles.warning]: type === 'warning',
        [styles.danger]: type === 'danger',
      })}
    >
      <div
        className={classNames(styles.icon, {
          [styles.default]: type === 'default',
          [styles.success]: type === 'success',
          [styles.warning]: type === 'warning',
          [styles.danger]: type === 'danger',
        })}
      >
        {getIcon()}
      </div>
      <div className={styles.text}>{children}</div>
      <div className={styles.buttons}>
        <div className={classNames(styles.actionButton, { [styles.hidden]: !withActionButton })}>
          <Button colorType='stroke' size='small' onClick={onClickButton}>
            {actionButtonText}
          </Button>
        </div>
        <div className={classNames(styles.closeButton, { [styles.hidden]: !withCloseButton })}>
          <IconButton size={24} icon={<CloseIconSvg viewBox='0 0 20 20' />} onClick={onClickButton} />
        </div>
      </div>
    </div>
  )
}
