import { FC, MouseEventHandler, PropsWithChildren, SVGProps } from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { LoadingIndicator } from '@components/atoms/LoadingIndicator'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

type Props = {
  componentType?: 'button' | 'a'
  href?: string
  anchorTarget?: '_self' | '_blank'
  colorType?: 'primary' | 'secondary' | 'stroke' | 'ghost' | 'error'
  size: 'small' | 'medium' | 'large'
  buttonType?: 'button' | 'submit' | 'reset'
  withIcon?: 'none' | 'left' | 'right'
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  isLoading?: boolean
  icon?: SVGProps<SVGElement> // viewBoxを指定して渡すことを忘れずに
}

export const Button: FC<PropsWithChildren<Props>> = ({
  children,
  componentType = 'button',
  href = '',
  anchorTarget = '_self',
  colorType = 'primary',
  size = 'medium',
  buttonType = 'button',
  withIcon = 'none',
  disabled = false,
  onClick,
  isLoading = false,
  icon,
}) => {
  const containerCNs = classNames(
    styles.container,
    {
      [styles[colorType]]: true, // colorType の名前でクラスを付与
    },
    {
      [styles[size]]: true, // size の名前でクラスを付与
    },
    {
      [styles.disabled]: disabled,
    },
    {
      [styles.loading]: isLoading && componentType === 'button', // a タグにはインジケーターを表示しない
    }
  )

  const contentCNs = classNames(
    styles.content,
    {
      [styles.withLeftIcon]: withIcon === 'left',
      [styles.withRightIcon]: withIcon === 'right',
    },
    {
      [styles.loading]: isLoading && componentType === 'button',
    }
  )

  const iconCNs = classNames(styles.icon, {
    [styles[size]]: true, // size の名前でクラスを付与
  })

  if (componentType === 'a') {
    return (
      <Link href={sanitizeUrl(href)}>
        <a className={containerCNs} target={anchorTarget}>
          <div className={contentCNs}>
            {withIcon === 'left' && <div className={iconCNs}>{icon}</div>}
            <div className={styles.text}>{children}</div>
            {withIcon === 'right' && <div className={iconCNs}>{icon}</div>}
          </div>
        </a>
      </Link>
    )
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={buttonType} className={containerCNs} onClick={onClick} disabled={disabled}>
      <div className={contentCNs}>
        {withIcon === 'left' && <div className={iconCNs}>{icon}</div>}
        <div className={styles.text}>{children}</div>
        {withIcon === 'right' && <div className={iconCNs}>{icon}</div>}
      </div>
      {isLoading && (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator size='small' color='white' />
        </div>
      )}
    </button>
  )
}
