import { FC } from 'react'
import { Avatar2 } from '@components/atoms/Avatar2'
import { IViewerBase } from '@/types'
import { getUserAvatar } from '@/utils'
import { observer } from 'mobx-react'
import styles from './index.module.scss'

type Props = {
  viewer: IViewerBase
}

export const DropDownListViewerHeader: FC<Props> = observer(({ viewer }) => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Avatar2 size='md' src={getUserAvatar(viewer)} />
      </div>
      <div className={styles.right}>
        <div className={styles.name}>{viewer?.name}</div>
        <div className={styles.email}>{viewer?.email}</div>
      </div>
    </div>
  )
})
