import { FC } from 'react'
import { observer } from 'mobx-react'
import { getUserAvatar, useStores } from '@/utils'
import { DbPlan } from '@/types'
import { DropDownMenuItemWithImage } from '../DropDownMenuItemWithImage'

export const DropDownListAttackListMenu: FC = observer(() => {
  const { viewer } = useStores()

  return (
    <>
      {viewer.viewer?.dbPlan === DbPlan.BASIC_PLAN && (
        <DropDownMenuItemWithImage
          key={viewer.viewer?.username}
          imageSrc={getUserAvatar(viewer.viewer)}
          label={viewer.viewer?.name}
          href={`/${viewer.viewer?.username}`}
        />
      )}
      {viewer.viewer?.userOwners?.map((userOwner) => {
        return (
          <DropDownMenuItemWithImage
            key={userOwner?.owner?.username}
            imageSrc={getUserAvatar(userOwner?.owner)}
            label={userOwner?.owner.name}
            href={`/${userOwner?.owner?.username}`}
          />
        )
      })}
    </>
  )
})
