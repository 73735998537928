import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { getSignInPath, isJaAndAtTopPage, sanitizeUrl } from '@/utils'
import { Button2 } from '@/components/atoms/Button2'
import classNames from 'classnames'
import styles from './index.module.scss'

export const DesktopHeaderRightSignInGroup: FC = () => {
  const { t } = useTranslation(['global'])
  const router = useRouter()

  return (
    <div className={styles.container}>
      <button
        type='button'
        className={classNames(styles.btn, {
          [styles.textWhite]: isJaAndAtTopPage(router),
        })}
        onClick={() => {
          router.push('/signup')
        }}
      >
        {t('新規登録')}
      </button>
      <button
        type='button'
        className={classNames(styles.btn, {
          [styles.textWhite]: isJaAndAtTopPage(router),
        })}
        onClick={() => {
          const path = getSignInPath(router.asPath)
          router.push(path)
        }}
      >
        {t('ログイン')}
      </button>
      <Button2
        componentType='a'
        hierarchy='primary'
        size='xl'
        href={sanitizeUrl(`${process.env.NEXT_PUBLIC_CONTACT_URL}`)}
        anchorTarget='_blank'
      >
        {t('担当者との面談を申し込む')}
      </Button2>
    </div>
  )
}
