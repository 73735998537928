import { FC } from 'react'
import { Avatar2 } from '@components/atoms/Avatar2'
import { observer } from 'mobx-react'
import Link from 'next/link'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

type Props = {
  imageSrc: string
  label: string
  href: string
}

export const DropDownMenuItemWithImage: FC<Props> = observer(({ imageSrc, label, href }) => {
  return (
    <Link href={sanitizeUrl(href)}>
      <a className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.left}>
            <Avatar2 size='sm' src={imageSrc} />
          </div>
          <div className={styles.right}>
            <div className={styles.name}>{label}</div>
          </div>
        </div>
      </a>
    </Link>
  )
})
